import axios from '@axios'

export default {
  namespaced: true,
  state: {
    toothFaces: [],
  },

  getters: {
    getToothFaces: state => state.toothFaces.map(el => ({ value: el.id, label: el.face })),
  },

  mutations: {
    SET(state, toothFaces) {
      state.toothFaces = toothFaces
    },
  },

  actions: {
    async fetch({ state, commit }) {
      if (!state.toothFaces.length) {
        try {
          const toothFaces = (await axios.get(`${process.env.VUE_APP_API_URL}/tooth_faces`)).data
          commit('SET', toothFaces)
        } catch (e) {
          return e
        }
      }
      return state.toothFaces
    },
  },
}
