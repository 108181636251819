export default [
  {
    path: '/forms/form-element/switch',
    name: 'forms-element-switch',
    component: () => import('@/views/forms/form-element/form-switch/Switch.vue'),
    meta: {
      pageTitle: 'Switch',
      breadcrumb: [
        {
          text: 'Form Elements',
        },
        {
          text: 'Switch',
          active: true,
        },
      ],
    },
  },
  {
    path: '/forms/form-element/checkbox',
    name: 'forms-element-checkbox',
    component: () => import('@/views/forms/form-element/form-checkbox/FormCheckbox.vue'),
    meta: {
      pageTitle: 'Checkbox',
      breadcrumb: [
        {
          text: 'Form Elements',
        },
        {
          text: 'Checkbox',
          active: true,
        },
      ],
    },
  },
  {
    path: '/forms/form-element/radio',
    name: 'forms-element-radio',
    component: () => import('@/views/forms/form-element/form-radio/FormRadio.vue'),
    meta: {
      pageTitle: 'Radio',
      breadcrumb: [
        {
          text: 'Form Elements',
        },
        {
          text: 'Radio',
          active: true,
        },
      ],
    },
  },
  {
    path: '/forms/form-element/rating',
    name: 'forms-element-rating',
    component: () => import('@/views/forms/form-element/form-rating/FormRating.vue'),
    meta: {
      pageTitle: 'Form Rating',
      breadcrumb: [
        {
          text: 'Form Elements',
        },
        {
          text: 'Form Rating',
          active: true,
        },
      ],
    },
  },
  {
    path: '/forms/form-element/date-time-picker',
    name: 'extensions-date-time-picker',
    component: () => import('@/views/forms/form-element/date-time-picker/DateTimePicker.vue'),
    meta: {
      pageTitle: 'Date Time Picker',
      breadcrumb: [
        {
          text: 'Extensions',
        },
        {
          text: 'Date Time Picker',
          active: true,
        },
      ],
    },
  },
]
