import axios from '@axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    loggedUser: {
      id: null,
      name: '',
      unreadChat: 0,
      address: {
        street: null,
        public_place: null, // string nullable
        number: null,
        complement: null,
        neighborhood: null,
        zip_code: null,
        observation: null,
        type_address: 'RES',
        city_id: null,
        state_id: null,
      },
      roles: [{
        id: null,
        name: '',
      }],
      photo: {
        url: null,
      },
    },
  },
  getters: {
    getLoggedUser: state => state.loggedUser,
  },
  mutations: {
    SET_LOGGED_USER(state, val) {
      // object assign ou spread replace values on deep nested. Lodash resolve this
      state.loggedUser = _.merge(state.loggedUser, val)
    },

    UPDATE_USER_INFO(state, payload) {
      const userInfo = JSON.parse(localStorage.getItem('userData')) || state.loggedUser

      // eslint-disable-next-line no-restricted-syntax
      for (const property of Object.keys(payload)) {
        // precisa ser assim para manter a reatividade
        state.loggedUser[property] = payload[property]
        const tmp = JSON.parse(JSON.stringify(state.loggedUser))
        state.loggedUser = tmp
        // precisa ser assim para manter a reatividade

        // Update key for localStorage
        userInfo[property] = payload[property]
      }

      // Store data in localStorage
      localStorage.setItem('userData', JSON.stringify(userInfo))
    },
  },
  actions: {
    async fetch({ state, commit }) {
      if (!state.loggedUser.id) {
        try {
          const loggedUser = (await axios.get(`${process.env.VUE_APP_API_URL}/me`))
          commit('SET_LOGGED_USER', loggedUser.data)
        } catch (e) {
          return e
        }
      }
      return state.loggedUser
    },
  },
}
