export default [
  {
    path: '/extensions/tour',
    name: 'extensions-tour',
    component: () => import('@/views/extensions/tour/Tour.vue'),
    meta: {
      pageTitle: 'Tour',
      breadcrumb: [
        {
          text: 'Extensions',
        },
        {
          text: 'Tour',
          active: true,
        },
      ],
    },
  },
]
