// import { initialAbility } from '@/libs/acl/config'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'

import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken && !axiosIns.noHeaders) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        } else {
          // eslint-disable-next-line no-param-reassign
          delete config.headers.Authorization
          // eslint-disable-next-line no-param-reassign
          delete config.headers.common.Authorization
        }

        // a propriedade _noHeaders setada como true ou aualquer outro valor é utilizada para consultas sem Autorization
        // devo deletar ela sempre após uma consulta sem autorização para que as próximas sejam autorizadas

        // eslint-disable-next-line no-param-reassign
        axiosIns.noHeaders = false
        return config
      },
      error => Promise.reject(error),
    )

    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        // const originalRequest = config
        const { response } = error

        if (response && response.status === 401) {
          // this.$ability.update(initialAbility)

          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')

          if (router.currentRoute.name !== 'auth-login') router.push({ name: 'auth-login' })
        }

        if (response && response.status === 403) {
          if (router.currentRoute.name !== 'email-verify') router.push({ name: 'email-verify' })
        }

        const subscriptionStatus = [
          'NOT_FOUND_SUBSCRIPTION',
          'CANCELED_ON_GRACE_PERIOD',
          'CANCELED_NO_GRACE_PERIOD',
          'UNPAID',
        ]

        if (response && response.status === 412 && subscriptionStatus.includes(response.data)) {
          if (router.currentRoute.name !== 'pages-pricing') router.push({ name: 'pages-pricing', params: { code: response.data } })
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  logout() {
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  fetchPatients(...args) {
    return this.axiosIns.post(this.jwtConfig.fetchPatientsEndpoint, ...args)
  }
}
