import axios from '@axios'

export default {
  namespaced: true,
  state: {
    banks: [],
  },

  getters: {
    getToSelect: state => state.banks.map(s => ({ value: s.id, label: `${s.bank_code} - ${s.name}`, active: s.active })),
  },

  mutations: {
    SET(state, banks) {
      state.banks = banks
    },
  },

  actions: {
    async fetch({ state, commit }) {
      if (!state.banks.length) {
        try {
          const banks = (await axios.get(`${process.env.VUE_APP_API_URL}/banks`)).data
          commit('SET', banks)
        } catch (e) {
          return e
        }
      }
      return state.banks
    },
  },
}
