import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import states from './states'
import teeth from './teeth'
import toothFaces from './tooth-face'
import banks from './banks'
import verticalMenu from './vertical-menu'
import company from './company'
import loggedUser from './logged-user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    states,
    banks,
    teeth,
    toothFaces,
    verticalMenu,
    company,
    loggedUser,
  },
  strict: process.env.DEV,
})
