import axios from '@axios'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    company: {
      id: null,
      configuration: {
        schedule: {},
        budget: {
          footer: '',
        },
      },
      addresses: {
        street: null,
        public_place: null, // string nullable
        number: null,
        complement: null,
        neighborhood: null,
        zip_code: null,
        observation: null,
        type_address: 'COM',
        city_id: null,
        state_id: null,
      },
      logo: {
        url: null,
      },
    },
  },
  getters: {
    getCompany: state => state.company,
  },
  mutations: {
    SET_COMPANY(state, val) {
      // object assign ou spread replace values on deep nested. Lodash resolve this
      state.company = _.merge(state.company, val)
    },
    DELETE_LOGO_COMPANY(state) {
      state.company.logo = null
    },
  },
  actions: {
    async fetch({ state, commit }) {
      if (!state.company.id) {
        try {
          const company = (await axios.get(`${process.env.VUE_APP_API_URL}/company`))
          commit('SET_COMPANY', company.data)
        } catch (e) {
          return e
        }
      }
      return state.company
    },

    async update({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/company`, payload)
          .then(resp => {
            commit('SET_COMPANY', resp.data)
            resolve(resp)
          })
          .catch(e => reject(e))
      })
    },
  },
}
