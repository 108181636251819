export default {
  // Endpoints API
  loginEndpoint: `${process.env.VUE_APP_API_URL}/login`,
  registerEndpoint: `${process.env.VUE_APP_API_URL}/register`,
  logoutEndpoint: `${process.env.VUE_APP_API_URL}/logout`,

  fetchPatientsEndpoint: `${process.env.VUE_APP_API_URL}/patients`,

  // Endpoints fake
  // loginEndpoint: '/jwt/login',
  // registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  // logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
