export default [
  // Pacientes
  {
    path: '/reception/patients',
    name: 'apps-patient-list',
    component: () => import('@/views/apps/patients/list/PatientsList.vue'),
    meta: {
      pageTitle: 'Pacientes',
      breadcrumb: [
        {
          text: 'Recepção',
          active: true,
        },
        {
          text: 'Pacientes',
          active: true,
        },
      ],
    },
  },

  // Agenda 1
  {
    path: '/reception/schedules',
    name: 'apps-schedules',
    component: () => import('@/views/apps/schedules/schedule/Schedule.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },

  // Agenda 2
  // {
  //   path: '/reception/schedules2',
  //   name: 'schedules2',
  //   component: () => import('@/views/apps/schedules/schedule2/Schedule.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //   },
  // },

  // Calendar
  // {
  //   path: '/reception/calendar',
  //   name: 'apps-calendar',
  //   component: () => import('@/views/apps/schedules/calendar/Calendar.vue'),
  // },

  // Prontuário
  {
    path: '/reception/patients/prontuary/:id',
    name: 'apps-patients-edit',
    component: () => import('@/views/apps/patients/edit/PatientsEdit.vue'),
    meta: {
      pageTitle: 'Paciente',
      breadcrumb: [
        {
          text: 'Recepção',
          active: true,
        },
        {
          text: 'Pacientes',
          to: '/reception/patients',
        },
        {
          text: 'Prontuário',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },

  // Caixa Geral
  {
    path: '/finances/general-cashier',
    name: 'general-cashier',
    component: () => import('@/views/apps/finances/general-cashier/List.vue'),
    meta: {
      pageTitle: 'Caixa  Geral',
      breadcrumb: [
        {
          text: 'Financeiro',
          active: true,
        },
        {
          text: 'Caixa Geral',
          active: true,
        },
      ],
    },
  },

  // Contas a Pagar
  {
    path: '/finances/bills-to-pay',
    name: 'apps-finances-bills-to-pay',
    component: () => import('@/views/apps/finances/bills-to-pay/list/List.vue'),
    meta: {
      pageTitle: 'Contas a Pagar',
      breadcrumb: [
        {
          text: 'Financeiro',
          active: true,
        },
        {
          text: 'Contas a Pagar',
          active: true,
        },
      ],
    },
  },

  // Contas a Receber
  {
    path: '/finances/bills-to-receive',
    name: 'apps-finances-bills-to-receive',
    component: () => import('@/views/apps/finances/bills-to-receive/list/List.vue'),
    meta: {
      pageTitle: 'Contas a Receber',
      breadcrumb: [
        {
          text: 'Financeiro',
          active: true,
        },
        {
          text: 'Contas a Receber',
          active: true,
        },
      ],
    },
  },

  // Plano de Contas
  {
    path: '/finances/account-plans',
    name: 'finances-account-plans',
    component: () => import('@/views/apps/finances/account-plans/AccountPlans.vue'),
    meta: {
      pageTitle: 'Plano de Contas',
      breadcrumb: [
        {
          text: 'Financeiro',
          active: true,
        },
        {
          text: 'Plano de Contas',
          active: true,
        },
      ],
    },
  },

  // Planos Dentais
  {
    path: '/dental-plans',
    name: 'dental-plans',
    component: () => import('@/views/apps/dental-plans/list/DentalPlans.vue'),
    meta: {
      pageTitle: 'Planos Dentais',
      breadcrumb: [
        {
          text: 'Financeiro',
          active: true,
        },
        {
          text: 'Planos Dentais',
          active: true,
        },
      ],
    },
  },

  // Fornecedores
  {
    path: '/providers',
    name: 'providers',
    component: () => import('@/views/apps/providers/list/List.vue'),
    meta: {
      pageTitle: 'Fornecedores',
      breadcrumb: [
        {
          text: 'Cadastros',
          active: true,
        },
        {
          text: 'Fornecedores',
          active: true,
        },
      ],
    },
  },

  // Tabelas de Preços
  {
    path: '/price/list',
    name: 'price-list',
    component: () => import('@/views/apps/price-list/list/PriceLists.vue'),
    meta: {
      pageTitle: 'Tabelas de Preços',
      breadcrumb: [
        {
          text: 'Cadastros',
          active: true,
        },
        {
          text: 'Tabelas de Preços',
          active: true,
        },
      ],
    },
  },

  // Procedimentos
  // TODO:
  // NÃO ESTAMOS MAIS USANDO PROCEDIMENTOS DIRETAMENTE. COM AS NOVAS TABELAS DE PREÇO OS PROCEDIMENTOS SÃO EDITADOS E CADASTRADOS E EXLUÍDOS POR ALI
  // NO FUTURO CASO SE CONSOLIDE ISSO DEVEMOS EXCLUIR OS ARQUIVOS DE @/views/apps/procedures/list/ LEVANDO EM CONSIDERAÇÃO O FATO DE QUE
  // AS CATEGORIAS DE PROCEDIMENTOS QUE ESTÃO AÍ DENTRO DEVEM SER MANTIDAS OU ALTERADAS DE LOCAL POIS ESTÃO SENDO UTILIZADAS NOS LOCAIS DE LISTAGEM DE PROCEDIMENTO E FILTRO POR CATEGORIA
  // {
  //   path: '/procedures/list',
  //   name: 'procedures-list',
  //   component: () => import('@/views/apps/procedures/list/Procedures.vue'),
  //   meta: {
  //     pageTitle: 'Procedimentos',
  //     breadcrumb: [
  //       {
  //         text: 'Cadastros',
  //         active: true,
  //       },
  //       {
  //         text: 'Procedimentos',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // Convênios
  {
    path: '/health-insurances/list',
    name: 'health-insurances-list',
    component: () => import('@/views/apps/health-insurances/list/HealthInsurances.vue'),
    meta: {
      pageTitle: 'Convênios',
      breadcrumb: [
        {
          text: 'Cadastros',
          active: true,
        },
        {
          text: 'Convênios',
          active: true,
        },
      ],
    },
  },

  // Modelos de Anamnese
  {
    path: '/anamnese-models/list',
    name: 'anamnese-models-list',
    component: () => import('@/views/apps/anamnese-models/list/AnamneseModels.vue'),
    meta: {
      pageTitle: 'Modelos de Anamnese',
      breadcrumb: [
        {
          text: 'Cadastros',
          active: true,
        },
        {
          text: 'Modelos de Anamnese',
          active: true,
        },
      ],
    },
  },

  // Cadastro de Dentistas
  {
    path: '/dentists/list',
    name: 'dentists-list',
    component: () => import('@/views/apps/dentists/list/DentistsList.vue'),
    meta: {
      pageTitle: 'Cadastro de Dentistas',
      breadcrumb: [
        {
          text: 'Cadastros',
          active: true,
        },
        {
          text: 'Cadastro de Dentistas',
          active: true,
        },
      ],
    },
  },
  // Cadastro de Modelos de Documentos
  {
    path: '/document-templates/list',
    name: 'document-templates-list',
    component: () => import('@/views/apps/document-templates/list/DocumentTemplatesList.vue'),
    meta: {
      pageTitle: 'Modelos de Documentos',
      breadcrumb: [
        {
          text: 'Cadastros',
          active: true,
        },
        {
          text: 'Modelos de Documentos',
          active: true,
        },
      ],
    },
  },

  // email not verified
  {
    path: '/email-verify',
    name: 'email-verify',
    component: () => import('@/views/pages/authentication/EmailVerify.vue'),
    meta: {
      layout: 'full',
      // resource: 'Auth', // com isso autorizo mesmo sem estar logado
      // redirectIfLoggedIn: true,
    },
  },
]
