import axios from '@axios'

export default {
  namespaced: true,
  state: {
    teeth: [],
  },

  getters: {
    // permanentTeeth
    // 'Arcada Superior', 'Arcada Inferior', 'Ambas Arcadas', 18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,

    // tissueTeeth
    // 'Arcada Superior', 'Arcada Inferior', 'Ambas Arcadas', 55, 54, 53, 52, 51, 61, 62, 63, 64, 65, 85, 84, 83, 82, 81, 71, 72, 73, 74, 75,

    // allTeeth
    // 'Arcada Superior', 'Arcada Inferior', 'Ambas Arcadas', 18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38, 55, 54, 53, 52, 51, 61, 62, 63, 64, 65, 85, 84, 83, 82, 81, 71, 72, 73, 74, 75,

    getAllTeeth: state => state.teeth.map(el => ({ value: el.id, label: el.number, data: el })),
    getPermanentTeeth: state => state.teeth.filter(e => e.type === 'PERMANENT' || e.type === 'MIXED').map(el => ({ value: el.id, label: el.number, data: el })),
    getTissueTeeth: state => state.teeth.filter(e => e.type === 'TISSUE' || e.type === 'MIXED').map(el => ({ value: el.id, label: el.number, data: el })),
  },

  mutations: {
    SET(state, teeth) {
      state.teeth = teeth
    },
  },

  actions: {
    async fetch({ state, commit }) {
      if (!state.teeth.length) {
        try {
          const teeth = (await axios.get(`${process.env.VUE_APP_API_URL}/teeth`)).data
          commit('SET', teeth)
        } catch (e) {
          return e
        }
      }
      return state.teeth
    },
  },
}
