import axios from '@axios'

export default {
  namespaced: true,
  state: {
    states: [],
  },

  getters: {
    getStates: state => state.states.map(s => ({ value: s.id, label: s.initials, initials: s.initials })),
  },

  mutations: {
    SET(state, states) {
      state.states = states
    },
  },

  actions: {
    async fetchStates({ state, commit }) {
      if (!state.states.length) {
        try {
          const states = (await axios.get(`${process.env.VUE_APP_API_URL}/states/1`)).data
          commit('SET', states)
        } catch (e) {
          return e
        }
      }
      return state.states
    },
  },
}
