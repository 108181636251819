import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import moment from 'moment'
import i18n from '@/libs/i18n'
import VCalendar from 'v-calendar'
// eslint-disable-next-line import/no-extraneous-dependencies
// import Echo from 'laravel-echo'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// comentei o pusher e o ECHO por que removi o websocket do laravel por não ser mais suportado

// eslint-disable-next-line import/no-extraneous-dependencies
// window.Pusher = require('pusher-js')

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_WEB_SOCKETS_KEY,
//   cluster: process.env.VUE_APP_WEB_SOCKETS_CLUSTER,
//   encrypted: !!process.env.VUE_APP_WEB_SOCKETS_ENCRYPTED,
//   wsHost: process.env.VUE_APP_WEB_SOCKETS_SERVER,
//   wsPort: 6001,
//   wssPort: 6001,
//   forceTLS: !!process.env.VUE_APP_WEB_SOCKETS_FORCE_TLS,
//   disableStats: true,
//   enabledTransports: ['ws', 'wss'],
//   authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
//   auth: {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//     },
//   },
// })

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
  // ...,                // ...other defaults
})

// remover se não aparecer erros desde 08 06 2023
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
// require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.filter('dateTime', (value, type = 'date', suffix = '') => {
  if (value) {
    const [YYYY, MM, DD] = (value.slice(0, 10)).split('-')
    const [H, m, s] = (value.slice(11, 19)).split(':')
    if (type === 'date') { // A data passada deve estar no padrão: YYYY-MM-DD
      return `${DD}/${MM}/${YYYY}`
    }
    if (type === 'dateTime') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
      return `${DD}/${MM}/${YYYY} ${H}:${m}:${s}${suffix}`
    }
    if (type === 'dateTimeNoSecond') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
      return `${DD}/${MM}/${YYYY} ${H}:${m}${suffix}`
    }
    if (type === 'time') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
      return `${H}:${m}:${s}${suffix}`
    }
    if (type === 'timeNoSecond') { // A data passada deve estar no padrão: YYYY-MM-DD HH:mm:ss
      return `${H}:${m}${suffix}`
    }
  }
  return ''
})

Vue.filter('age', (value, format = 'Y') => {
  if (value && value.length === 10) {
    if (moment(value, 'YYYY-MM-DD').isValid()) {
      const years = moment().diff(value, 'years')
      const days = moment().diff(value, 'days')
      if (format === 'Y') return years || 0
      if (format === 'D') return days || 0
    }
  }
  return 0
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
