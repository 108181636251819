export default [
  {
    path: '/components/popover',
    name: 'components-popover',
    component: () => import('@/views/components/popover/Popover.vue'),
    meta: {
      pageTitle: 'Popover',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Popover',
          active: true,
        },
      ],
    },
  },
  {
    path: '/components/progress',
    name: 'components-progress',
    component: () => import('@/views/components/progress/Progress.vue'),
    meta: {
      pageTitle: 'Progress',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Progress',
          active: true,
        },
      ],
    },
  },
  {
    path: '/components/tooltip',
    name: 'components-tooltip',
    component: () => import('@/views/components/tooltip/Tooltip.vue'),
    meta: {
      pageTitle: 'Tooltip',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Tooltip',
          active: true,
        },
      ],
    },
  },
]
